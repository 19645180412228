import React from 'react';
import Discord from '../components/Discord';

import factions from '../data/faction.json';

export default class Home extends React.Component{
    
    render(){
        console.log(Discord.isAppSiteMember())
        return (
            <div className="container">
              <div className="row text-center pt-2">
                <div className={ `col-md-12 mb-3${Discord.isAppSiteMember() ? ' d-none' : ''}` }>
                    <strong>To access Faction Only pages you must be part of our Faction Discord Server</strong>
                    <Discord 
                        className="btn btn-primary ms-3" 
                    />
                </div>
                <div className="col-md-12">
                    <h3 className="text-decoration-underline mb-4">Welcome to the Baddest Factions on the Planet!!!</h3>
                    <p className="mb-0">We at Acidbath/Acidreigns strive to be honest and the best at the <a href="https://wwechampions.com/" target="_blank" rel="noreferrer">WWE Champions</a> game wile preserving a family first atmosphere.</p>
                    <p className="mb-0">We are always there for our fellow faction family in helping them with all problems no matter how big or small.</p>
                    <p className="mb-0">We offer two factions <a href="https://wwechampions.com/faction/Acidbath">Acidbath</a> and <a href="https://wwechampions.com/faction/Acidreigns" target="_blank" rel="noreferrer">Acidreigns</a> both factions are on the same discord server so members can communicate with either group.</p>
                    <strong className="fst-italic mb-5">* There are slight differences between our two factions</strong>
                </div>
              </div>
              <div className="row">
                  <div className="col-md-6 mt-3">
                      <div className="text-center">
                        <img className="img-acid" src={ require(`../${factions[0].shortimg}`) } alt={factions[0].name + " Logo"}/>
                      </div>
                      <ul>
                          <li>Geared towards experienced, hardcore, and competitive players</li>
                          <li>Discord required</li>
                          <li>Required minimums</li>
                      </ul>
                      <div className="text-center">
                          <a className="btn btn-success" target="_blank" href="https://wwechampions.com/faction/Acidbath" rel="noreferrer">View Official Stats</a>
                      </div>
                  </div>
                  <div className="col-md-6 mt-3">
                      <div className="text-center">
                        { 
                            undefined !== factions ? 
                            <img className="img-acid" src={ require(`../${factions[1].shortimg}`) } alt={factions[1].name + " Logo"}/>
                            : '' 
                        }
                      </div>
                      <ul>
                          <li>Geared towards training and honing skills, faction breaks, and newer rosters</li>
                          <li>Discord recommended</li>
                          <li>More lax minimums</li>
                      </ul>
                      <div className="text-center">
                          <a className="btn btn-success" target="_blank" href="https://wwechampions.com/faction/Acidreigns" rel="noreferrer">View Official Stats</a>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-12">
                      <div className="text-center">
                          <h4 className="mt-5">Thank you for visiting our faction, please feel free to take a look, we hope to hear from you soon!</h4>
                      </div>
                  </div>
              </div>
            </div>
        );

    }

}