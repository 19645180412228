import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Header from './structure/Header';
import Footer from './structure/Footer';

import {
	Home,
  Rules,
  Champs,
  Guides,
  Superstars,
  JoinUs
} from './pages/index';

export default class App extends React.Component{

  render(){

    return (
      <>
        <Header />
        <Router>
          <Routes>
            <Route exact path='/' element={
              <Home />
              } 
            />
            <Route exact path='/rules' element={
              <Rules />
              } 
            />
            <Route exact path='/faction-titles' element={
              <Champs />
              } 
            />
            <Route exact path='/guides' element={
              <Guides />
              } 
            />
            <Route exact path='/superstars' element={
              <Superstars />
              } 
            />
            <Route exact path='/join-us' element={
              <JoinUs />
              } 
            />
          </Routes>
        </Router>
        <Footer />
      </>
      
    );
  }
}
