import React from 'react';
import ReactModal from 'react-modal';
import { Icon } from '@iconify/react';

import Discord from '../components/Discord';


ReactModal.setAppElement('main');

export default class Guides extends React.Component{
    render(){
        
        let entourage = [
            "2200 Gem Coaches",
            "2200 Gem Trainers",
            "Finisher Coaches",
            "Finisher Trainers",
            "Gem Match Charge Coaches",
            "Gem Match Charge Trainers",
            "MP Reducing Coaches",
            "MP Reducing Trainers",
            "Move Damage Coaches",
            "MP Trainers",
        ];

        return (
            <>
            {
                ! Discord.isAppSiteMember() ? 
                <Discord className="d-none" clicked="true" /> : 
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <h4>Quick Links</h4>
                            <a className="d-block text-dark fs-5 mb-3" href="#entourage"><Icon icon="ph:caret-right-fill" className="align-middle"></Icon> Entourage</a>
                            <a className="d-block text-dark fs-5 mb-3" href="#evolve-reqs"><Icon icon="ph:caret-right-fill" className="align-middle"></Icon> Evolve/Enhance Requirements</a>
                        </div>
                        <div className="col-md-9">
                            <h3 id="entourage">Entourages</h3>
                            {
                                entourage.map((tc) => {
                                    return(
                                        <div key={tc} className="d-inline-block col-md-5">
                                            <strong className="d-block mt-3">{ tc }</strong>
                                            <img className="belt" src={ require(`../imgs/help/${ tc.replaceAll(' ','') }.png`) } alt={ `${ tc }` } />
                                        </div>
                                    )
                                })
                            }
                            <h3 id="evolve-reqs" className="mt-5">Evolve/Enhance Requirements</h3>
                            <div className="d-inline-block col-md-5">
                                <strong className="d-block">Hall Of Fame</strong>
                                <img className="belt" src={ require("../imgs/help/hof-reqs.jpg") } alt="HOF Reqs" />
                            </div>
                            <div className="d-inline-block col-md-5">
                                <strong className="d-block">Icons of Wrestlemania</strong>
                                <img className="belt" src={ require("../imgs/help/iow-reqs.png") } alt="IOW Reqs" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>
            
        );
    }
}

