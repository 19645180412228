import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-dark">
            <div className="container">
                <div className="row">
                    <i className="text-white">Updated as of { new Date().toLocaleString("en-US", {dateStyle: 'full', timeStyle: 'short'}) }</i>
                </div>
                {
                    "/superstars" === window.location.pathname ?
                    <div className="row">
                        <p className="text-white">Wrestler Silhouette Image by <a href="https://pixabay.com/users/mohamed_hassan-5229782/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3307961">Mohamed Hassan</a> from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3307961">Pixabay</a></p>
                    </div> : ''
                }
                {
                    "/guides" === window.location.pathname ?
                    <div className="row">
                        <p className="text-white">NerdLab artwork is created by fellow gamers of the community for the community.</p>
                    </div> : ''
                }
            </div>
        </footer>
    );
}

export default Footer;