import React from 'react';
import { Icon } from '@iconify/react';

import factions from '../data/faction.json';

export default class Rules extends React.Component{

    render(){
        if( undefined !== factions ){
            const colCount = 12 / factions.length;

            return (
                <div className="container">
                    <div className="row text-center py-3">
                        <h4>Our rules are simple and ensure that our factions remain fantastic, fair and fun. <br />
                        If you have any questions please feel free to contact us.</h4>
                    </div>
            
                    <div className="row">
                        {
                            factions.map(function(faction, index){
                                let support = '';

                                if( faction.support ){
                                    support = faction.support.join(',');
                                    let divider = '==========================';
                                    let mailto = `mailto:${support}`;
                                    let subject = `subject=Question about ${faction.name}`;
                                    let body = encodeURIComponent(`We are glad to hear from you, please ask your question below\n${divider}\n\n`);

                                    support = <a className="btn btn-primary mb-2" href={ `${mailto}?${subject}&body=${body}`}>Contact Us <Icon icon="icon-park-outline:send-email"></Icon></a>
                                        
                                    
                                }
                                
                                

                                return(
                                    <div key={ faction.name + '-' + index} className={"col-md-" + colCount}>
                                        <div className="text-center">
                                            <img className="img-acid" src={ require(`../${faction.shortimg}`) } alt={faction.name + " Logo"}/>
                                        </div>
                                        <h4 className="text-center text-decoration-underline">Rules</h4>
                                        <ul>
                                        { 
                                            undefined !== faction.rules ?
                                            faction.rules.map(function(value, i){
                                                return <li key={ faction.name + '_' + i}>{value}</li>
                                            }) : ''
                                        }
                                        </ul>
                                        <h4 className="text-center text-decoration-underline">Minimums</h4>
                                        <ul>
                                        { 
                                            undefined !== faction.minimums ?
                                            faction.minimums.map(function(value, i){
                                                return <li key={ faction.name + '_' + i}>{value}</li>
                                            }) : ''
                                        }
                                        </ul>
                                        <h4 className="text-center text-decoration-underline">Faction Boss</h4>
                                        <ul>
                                        { 
                                            undefined !== faction.faction_boss ?
                                            faction.faction_boss.map(function(value, i){
                                                return <li key={ faction.name + '_' + i}>{value}</li>
                                            }) : ''
                                        }
                                        </ul>
                                        {
                                            support ? 
                                            <div className="text-center">{support}</div> : ''
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }
}
