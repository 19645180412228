import React from 'react';
import { Icon } from '@iconify/react';

import wwe_data from '../data/wwe.json';

export default class Admin extends React.Component{

	constructor(props) {
		super(props);

		this.wwe = wwe_data;
				
        this.addSuperstar = this.addSuperstar.bind(this);
        this.editSuperstar = this.editSuperstar.bind(this);
		this.saveSuperstar = this.saveSuperstar.bind(this);
		this.removeSuperstar = this.removeSuperstar.bind(this);
		this.sortSuperstars = this.sortSuperstars.bind(this);
		
        this.superstarForm = this.superstarForm.bind(this);
        this.renderRemoveSuperstarMessage = this.renderRemoveSuperstarMessage.bind(this);
        this.renderOption = this.renderOption.bind(this);

        this.stopActions = this.stopActions.bind(this);

		this.addData = this.addData.bind(this);
		this.removeData = this.removeData.bind(this);
		this.showData = this.showData.bind(this);
		this.editData = this.editData.bind(this);
		this.saveData = this.saveData.bind(this);
		this.editDataForm = this.editDataForm.bind(this);

		this.exportData = this.exportData.bind(this);

		this.sortSuperstars();
	}
	
    stopActions(){
        this.setState({
            superstar: undefined,
            addingNewSuperStar: false,
            removingSuperstar: false,
            editingSuperstar: false,
            showingBuild: false,
			editingData: false
        })
    }

    addSuperstar(){
        this.setState({
            addingNewSuperStar: true
        })
    }
	
    editSuperstar(superstarIndex){
        this.setState({ 
            editingSuperstar: true,
            superstar: parseInt(superstarIndex) 
        });

    }

    saveSuperstar(index, sName, sGuide,  sGender, sClass, sEra, sStable){
		if( undefined !== index ){
			this.wwe.roster[index].name = sName;
			this.wwe.roster[index].guide = sGuide;
			this.wwe.roster[index].gender = sGender;
			this.wwe.roster[index].class = sClass;
			this.wwe.roster[index].era = sEra;
			this.wwe.roster[index].stable = sStable;

		}else{
			this.wwe.roster.push({
				name: sName,
				guide: sGuide,
				gender: sGender,
				class: sClass,
				era: sEra,
				stable: sStable,
				hasBuilds: false
			})
		}
        
        this.sortSuperstars();
        
		// force base page update
		this.setState({})
    }

	removeSuperstar(superstarIndex){
        this.wwe.roster = this.wwe.roster.filter((s, i) => i !== superstarIndex )
        
        this.sortSuperstars();
		
		// force base page update
		this.setState({});
	}

	sortSuperstars(){
		// Sort characters in alphabetical order by first name
		this.wwe.roster = Object.values(this.wwe.roster).sort((a,b) => {
			let at = a.name.toLowerCase();
			let bt = b.name.toLowerCase();
			return (at > bt)?1:((at < bt)?-1:0);  
		}).map( (superstar) => { 
		var hasBuild = false;

		// check if superstar has builds   
		try{
			require(`../imgs/superstars/${superstar.guide}/${superstar.guide}-1.png`);
			
			hasBuild = true
		}catch(error){
			hasBuild = false
		}

			superstar.hasBuilds = hasBuild;

			return superstar;
		});

	}
	
    superstarForm(){
        
		const formRef = React.createRef();

		let sName = "";
		let sGuide = "";
		let sClass = "";
		let sGender = "";
		let sEra = "";
		let sStable = "";

		let classCount = this.wwe.classes.length;
		let eraCount = this.wwe.eras.length;
		let stableCount = this.wwe.stables.length;

		if( undefined !== this.state.superstar && undefined !== this.wwe.roster[this.state.superstar] ){
			let superstar = this.wwe.roster[this.state.superstar];

			sName = superstar.name;
			sGuide = superstar.guide;
			sClass = superstar.class;
			sGender = superstar.gender;
			sEra = superstar.era;
			sStable = superstar.stable;

		}

		return(
			<form className="container border border-3 my-3" ref={formRef}  onSubmit={ (e) => {
					e.preventDefault();

					if( e.target.checkValidity()){
						let n = formRef.current[1].value;
						let t = formRef.current[2].value;
						let g = formRef.current[3].checked ? "m" : "f";
						let c = "acrobat";
						let e = "attitude";
						let s = [];
						
						let start = 5;
						let end = start + classCount;

						// iterate thru class option
						for(var x = start; x < end; x++){
							if( formRef.current[x].checked ){
								c = formRef.current[x].value;
							}
						}

						start = end;
						end = start + eraCount;

						// iterate thru era option
						for(var y = start; y < start + eraCount; y++){
							if( formRef.current[y].checked ){
								e = formRef.current[y].value;
							}
						}

						start = end;
						end = start + stableCount;

						// iterate thru stable option
						for(var z = start; z < start + stableCount; z++){
							if( formRef.current[z].checked ){
								s.push(formRef.current[z].value);
							}
						}

						this.saveSuperstar(this.state.superstar, n, t, g, c, e, s);
						this.stopActions()
					}
				} }>

				<div className="row py-2 border-bottom border-4 mb-3">
					<div className="col-md-12 text-center">
						<h3 className="d-inline">{ "" !== sName ? `Editing ${sName}` : 'Add a New Superstar' }</h3>
						<div className="d-inline float-end">
							<button className="btn btn-secondary" onClick={ this.stopActions }>
								<Icon icon="material-symbols:close" />
							</button>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<strong>Name</strong>
						<input 
							required
							type="text" 
							className="form-control" 
							id="manager-superstar-name" 
							placeholder="Name" 
							defaultValue={ sName }
							/>
					</div>
					<div className="col-lg-6">
						<strong>Database Tag</strong>
						<input 
							required
							type="text" 
							className="form-control" 
							id="manager-superstar-guide" 
							placeholder="Database Tag" 
							defaultValue={ sGuide }
							/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<strong className="d-block">Gender</strong>
						<div className="form-check form-check-inline">
							<input 
								required
								className="form-check-input" 
								type="radio" 
								value="m" 
								name="manager-superstar-gender" 
								id="manager-superstar-gender-male" 
								defaultChecked={ "m" === sGender }
								/>
							<label className="form-check-label" htmlFor="manager-superstar-gender-male">
								Male
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input 
								required
								className="form-check-input" 
								type="radio" 
								value="f" 
								name="manager-superstar-gender" 
								id="manager-superstar-gender-female" 
								defaultChecked={ "f" === sGender }
								/>
							<label className="form-check-label" htmlFor="manager-superstar-gender-female">
								Female
							</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<strong className="d-block">Classes</strong>
						{ 
							this.wwe.classes.map(function(c, i){
								let v = c.toLowerCase();

								return(
									<div className="form-check form-check-inline align-top" key={ `edit-${v}-class`}>
										<input className="form-check-input" 
											required
											type="radio" 
											value={ v } 
											name="edit-superstar-class" 
											id={ `edit-${v}-class` } 
											defaultChecked={ sClass === v }
											/>
										<label className="form-check-label" htmlFor={ `edit-${v}-class` }>{ c }</label>
									</div>
								)
							})
						}
					</div>          
				</div>
				<div className="row">
					<div className="col-lg-12">
						<strong className="d-block">Eras</strong>
						{
							this.wwe.eras.map(function(e,i){
								let v = e.toLowerCase().replaceAll(' ', '_')
								return(
									<div className="form-check form-check-inline align-top" key={ `edit-${e}-era`}>
										<input className="form-check-input" 
											required
											type="radio" 
											value={ v } 
											name="edit-superstar-era" 
											id={ `edit-${v}-era` } 
											defaultChecked={ sEra === v }
											/>
										<label className="form-check-label" htmlFor={ `edit-${v}-era` }>{ e }</label>
									</div>
								);
							})
						}
					</div>          
				</div>
				<div className="row">
					<div className="col-lg-12">
						<strong className="d-block">Stables</strong>
						{
							this.wwe.stables.map(function(s,i){
								let v = s.toLowerCase().replaceAll(' ', '_')
								return(
									<div className="form-check form-check-inline align-top" key={ `edit-${v}-stable`}>
										<input className="form-check-input" 
											type="checkbox" 
											value={ v } 
											name="edit-superstar-stable" 
											id={ `edit-${v}-stable` } 
											defaultChecked={ sStable.includes( v ) }
											/>
										<label className="form-check-label" htmlFor={ `edit-${v}-stable` }>{ s }</label>
									</div>
								);
							})
						}
					</div>          
				</div>
				<div className="row border-top border-4 py-2">
					<div className="col-lg-12 text-center">
						<button className="btn btn-success me-3" >Save</button>
						<button className="btn btn-danger" onClick={ this.stopActions }>Cancel</button>
					</div>
				</div>
			</form>
		)
	  
  	}
	
	renderRemoveSuperstarMessage(){
        if( ! this.state.removingSuperstar ){
            return;
        }

        let currentStar = this.wwe.roster[this.state.superstar];

        return(
            <div className="row h-100">
                <div className="col-lg-12 border-bottom border-3">
                    <div className="pt-2 text-center">
                        <button 
                            className="float-end text-secondary border border-secondary border-2 fs-5 p-1 pt-0 mb-2" 
                            onClick={ this.stopActions }><Icon icon="material-symbols:close" /></button>
                        <h3>{`Removing ${currentStar.name}`}</h3>
                    </div>
                </div>
                <div className="col-lg-12 py-4">
                    <div className="text-center">
                        <strong className="fs-3">Are you sure you want to remove <span className="text-danger">{currentStar.name}</span> this can't be undone?</strong>
                    </div>
                </div>
                <div className="col-lg-12 border-top border-3 align-self-end">
                    <div className="py-2 text-center">
                        <button className="btn btn-success me-3" onClick={() =>{
                            this.removeSuperstar(parseInt(this.state.superstar))
                            this.stopActions()
                            
                        }}>Save</button>
                        <button className="btn btn-danger" onClick={ this.stopActions }>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }

	editData(){
		this.setState({
			...this.state,
			editingData: true
		})
	}

	removeData(e){
		e.target.parentNode.classList = 'd-none';
	}

	addData(category, val){
		let optionList = document.querySelector(`#${category}-listing`);
		
		let div = document.createElement('DIV');
		let input = document.createElement('INPUT');
		let button = document.createElement('BUTTON');
		let span = document.createElement('SPAN');

		div.classList = "d-inline-block me-3";

		input.type = "hidden";
		input.value = val;
		input.name =  `edit-${category}-${val}`;
		input.id =  `edit-${category}-${val}`;

		button.classList = "btn btn-sm edit-data align-baseline text-danger fw-bold";
		button.innerHTML = "&mdash;";
		button.addEventListener( 'click', this.removeData );

		span.classList = "align-middle";
		span.innerText = val;

		div.append(input);
		div.append(button);
		div.append(span);

		optionList.append(div)
	}

	saveData(){
		let classInputs = document.querySelectorAll(`input[id^="edit-classes-"]`)
		let classes = [];
		let eraInputs = document.querySelectorAll(`input[id^="edit-eras-"]`)
		let eras = [];
		let stableInputs = document.querySelectorAll(`input[id^="edit-stables-"]`)
		let stables = [];

		classInputs.forEach((c) => {
			if( ! c.parentNode.classList.contains('d-none') ){
				classes.push(c.value)
			}
		})

		eraInputs.forEach((e) => {
			if( ! e.parentNode.classList.contains('d-none') ){
				eras.push(e.value)
			}
		})

		stableInputs.forEach((s) => {
			if( ! s.parentNode.classList.contains('d-none') ){
				stables.push(s.value)
			}
		})

		this.wwe.classes = classes;
		this.wwe.eras = eras;
		this.wwe.stables = stables;

		this.stopActions();
	}


	editDataForm(){
		const formRef = React.createRef();

		return(
			<form className="container border border-3 my-3" ref={formRef}  onSubmit={ (e) => {
					e.preventDefault();
				} }>

				<div className="row py-2 border-bottom border-4 mb-3">
					<div className="col-md-12 text-center">
						<h3 className="d-inline">Editing WWE Data</h3>
						<div className="d-inline float-end">
							<button className="btn btn-secondary" onClick={ this.stopActions }>
								<Icon icon="material-symbols:close" />
							</button>
						</div>
					</div>
				</div>
				{ this.renderOption('classes') }
				{ this.renderOption('eras') }
				{ this.renderOption('stables') }
				<div className="row border-top border-4 py-2">
					<div className="col-lg-12 text-center">
						<button className="btn btn-success me-3" onClick={ this.saveData }>Save</button>
						<button className="btn btn-danger" onClick={ this.stopActions }>Close</button>
					</div>
				</div>
			</form>
		)
	}

	renderOption(category){
		const showData = this.showData;

		let categories = 
			"classes" === category ? this.wwe.classes : 
			"eras" === category ? this.wwe.eras : 
			"stables" === category ? this.wwe.stables : [];

		return(
			<>
			<div className="row mt-4">
				<div className="col-lg-12" id={ `${category}-listing` }>
					<strong className="d-block text-capitalize">{ category }</strong>
					{ 
						categories.map(function(c, i){
							return showData(category, c );
						})
					}
				</div>          
			</div>
			<div className="row">
					<div className="col-lg-6 pe-0">
						<input
							className="form-control"
							type="text" 
							name={`edit-new-${category}`} 
							id={ `edit-new-${category}` }
						/>
					</div>
					<div className="col-lg-6 ps-0">
						<button className="btn btn-sm edit-data text-success fw-bold fs-5" onClick={ (e) => {
							let newVal = document.querySelector(`#edit-new-${category}`);
							
							if( "" !== newVal.value ){
								this.addData(category, newVal.value );
							}

							newVal.value = "";
						} }>+</button>
					</div>
				</div>
			</>
		)
	}

	showData(category, val){
		return(
			<div className="d-inline-block me-3" key={ `edit-${category}-${val}`}>
				<input
					type="hidden" 
					value={ val } 
					name={ `edit-${category}-${val}` } 
					id={ `edit-${category}-${val}` } 
					/>
				<button className="btn btn-sm edit-data align-baseline text-danger fw-bold" onClick={ this.removeData }>&mdash;</button>
				<span className="align-middle">{ val }</span>
			</div>
		)
	}

    exportData(){
        let date = `\n\t"lastUpdate": "${new Date().toLocaleString("en-US", {dateStyle: 'full', timeStyle: 'short'})}"`;
        let roster = '';
		let classes = '';
		let eras = '';
		let stables = '';

		// classes
        this.wwe.classes.forEach(c => {
            classes += `\t\t"${c}",\n`
        });

		classes = `\n\t"classes": [\n${classes.slice(0, -2)}\n\t]`;

		// eras
        this.wwe.eras.forEach(c => {
            eras += `\t\t"${c}",\n`
        });

		eras = `\n\t"eras": [\n${eras.slice(0, -2)}\n\t]`;

		// stables
        this.wwe.stables.forEach(c => {
            stables += `\t\t"${c}",\n`
        });

		stables = `\n\t"stables": [\n${stables.slice(0, -2)}\n\t]`;

		// roster
		this.wwe.roster.forEach(star => {
            let i = `\n\t\t\t`;
            let n = `${i}"name": "${star.name.replaceAll('"', '\\"')}"`;
            let t = `${i}"guide": "${star.guide}"`;
            let g = `${i}"gender": "${star.gender}"`;
            let c = `${i}"class": "${star.class}"`;
            let e = `${i}"era": "${star.era}"`;
            let s = `${i}"stable": "${star.stable}"`;

            roster += `\t\t{${n},${t},${g},${c},${e},${s}\n\t\t},\n`
        });
        roster = `\n\t"roster": [\n${roster.slice(0, -2)}\n\t]`;
		
        const sData = new Blob([`{${date},${classes},${eras},${stables},${roster}\n}`], {
            type: "application/json;charset=utf-8}"
        });

        const element = document.createElement("a");

        element.download = "wwe.json";
        element.href = URL.createObjectURL(sData);

		element.click();

    }
}
