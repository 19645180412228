import React from "react";
import { Icon } from '@iconify/react';

import Discord from '../components/Discord';

const Navigation = () => {
    return (
        <div id="nav-container" className="container">
            <nav className="row navbar navbar-expand-lg bg-body-tertiary border-start border-end border-white border-5">
                <div className="container-fluid">
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav flex-fill">
                        <li className="nav-item flex-fill text-center bg-dark">
                            <a className="nav-link text-white fs-3" aria-current="page" href="/">
                                <Icon icon="simple-icons:wwe" className="align-middle"></Icon> Home</a>
                        </li>
                        <li className="nav-item flex-fill text-center bg-dark">
                            <a className="nav-link text-white fs-3" aria-current="page" href="rules">
                                <Icon icon="simple-icons:wwe" className="align-middle"></Icon> Rules</a>
                        </li>
                        <li className="nav-item flex-fill text-center bg-dark">
                            <a className="nav-link text-white fs-3" aria-current="page" href="faction-titles">
                                <Icon icon="simple-icons:wwe" className="align-middle"></Icon>Faction Titles</a>
                        </li>
                        {
                            Discord.isAppSiteMember() ?
                            <li className="nav-item flex-fill text-center dropdown">
                                <button className="nav-link dropdown-toggle text-white fs-3 bg-dark w-100 border-0" data-bs-toggle="dropdown">
                                    <Icon icon="simple-icons:wwe" className="align-middle"></Icon>Help
                                </button>
                                <ul className="dropdown-menu w-100">
                                    <li><a href="guides" className="dropdown-item fs-5"><Icon icon="simple-icons:wwe" className="align-middle"></Icon>Trainer/Coaches</a></li>
                                    <li><a href="superstars" className="dropdown-item fs-5"><Icon icon="simple-icons:wwe" className="align-middle"></Icon>Superstars</a></li>
                                </ul>                    
                            </li> : ''
                        }
                        
                        <li className="nav-item flex-fill text-center bg-dark">
                            <a className="nav-link text-white fs-3" aria-current="page" href={`https://discord.com/channels/731510787650093076/`} target="_blank" rel="noreferrer">
                                <Icon icon="simple-icons:wwe" className="align-middle"></Icon>Discord</a>
                        </li>
                        <li className="nav-item flex-fill text-center bg-dark">
                            <a className="nav-link text-white fs-3" aria-current="page" href="join-us">
                                <Icon icon="simple-icons:wwe" className="align-middle"></Icon>Join Us</a>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navigation;
