import React from 'react';

import factions from '../data/faction.json';

export default class Champs extends React.Component{
    render(){

        if( ! factions.length ){
            return
        }

        let today = new Date();
        let dayOfWeek = today.getDay();
        
        let endWeek = new Date();
        endWeek.setDate(`${ today.getDate() + Math.abs(dayOfWeek - 6) - 7 }`);

        let startWeek = new Date(endWeek);
        startWeek.setDate(`${ endWeek.getDate() - 6 }`)
        
        let weekPeriod = `${startWeek.toLocaleString("en-US", {dateStyle: 'short'})} - ${endWeek.toLocaleString("en-US", {dateStyle: 'short'})}`;
        let factionChamps = "";

        if( factions.length ){
            factions.forEach((faction) => {
                if( ! Object.values(faction.champions).length ){
                    return;
                }

                factionChamps = <>{factionChamps}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h3 className="display-1 text-acid border-bottom border-5 border-secondary mx-5">{ weekPeriod }<br />{faction.name} Faction Quest Champions</h3>  
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {
                        Object.keys(faction.champions).map((quest) => {
                            if( 'fued' === quest || ! faction.champions[quest].length ){
                                return false;
                            }

                            let champ = faction.champions[quest];
                            let beltImg = require(`../imgs/belts/${quest}.jpg`);

                            return(
                                <div key={ `${quest}-champ` } className="col text-center my-3">
                                    <img className="d-block mx-auto belt" src={ beltImg } alt={ `${quest} Champion Belt`}/>
                                    <figcaption className="fw-semibold text-capitalize">{ quest } Champion</figcaption>
                                    <figcaption className="fw-semibold">{champ}</figcaption>
                                </div>
                            );
                        })
                    }
                    </div>
                </>
            })
        }

        return (
            <div className="container">
                <div className="row text-center pt-2">
                    <div className="col-md-12">
                        <h4>For fun, each week the member with the most contributions in Faction Group Quests is awarded the weekly faction title. We also award titles for Feuds, Faction Boss, and Kickoff Tours.</h4>
                    </div>
                </div>
                <div className="row px-4">
                    <div className="col-md-12">
                        <h4>Feud & Flash Feud Title Participation Instructions</h4>
                        <ol>
                            <li>Take a screenshot of the Warscore Contest Page</li>
                            <li>Flash Feuds score for each feud disappears when each feud ends, total is added up on the contest Warscore.</li>
                            <li>We use individual Warscore screenshots to determine winners of Faction Titles</li>
                            <li>No Score = No Title</li>
                        </ol>
                    </div>
                </div>
                { factionChamps }
            </div>
        );

    }

}
  