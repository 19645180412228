import React from 'react';
import ReactModal from 'react-modal';
import { Icon } from '@iconify/react';

import Discord from '../components/Discord';
import Admin from '../components/Admin';

ReactModal.setAppElement('main');

const silhouette = require('../imgs/superstars/silhouette.png');

export default class Superstars extends Admin{
    
    constructor(props){
        super(props);

        this.state = {
            ...this.state,
            buildOnly: false,
            class: "acrobat",
            gender: "both",
            era: [],
            stable: []
        }
        
        this.classChanged = this.classChanged.bind(this);
        this.genderChanged = this.genderChanged.bind(this);
        this.isBuildOnly = this.isBuildOnly.bind(this);
        this.eraChanged = this.eraChanged.bind(this);
        this.stableChanged = this.stableChanged.bind(this);

        this.showingBuild = this.showingBuild.bind(this);
        this.closingBuild = this.closingBuild.bind(this);
        this.showBuilds = this.showBuilds.bind(this);

        
        this.renderSuperstarGrid = this.renderSuperstarGrid.bind(this);
        
        
        let parameters = new URLSearchParams(window.location.search);
		parameters = Object.fromEntries(parameters.entries());

        if( parameters.ss ){
            this.wwe.roster.map( (superstar,s) => {
                if( superstar.guide === parameters.ss ){
                    this.state = {
                        ...this.state,
                        class: superstar.class,
                        superstar: s,
                        showingBuild: superstar.hasBuilds
                    }
                }
                return false;
            })
        }
    }

    classChanged(event){
        this.setState({
            ...this.state,
            class: event.target.value
        })
    }

    genderChanged(event){
        this.setState({
            ...this.state,
            gender: event.target.value
        })
    }

    isBuildOnly(event){
        this.setState({
            ...this.state,
            buildOnly: event.target.checked
        })
    }

    eraChanged(event){
        let eras = this.state.era;
        let v = event.target.value;

        if( event.target.checked && ! eras.includes(v)){
            eras.push(v);
        }else{
            eras = eras.filter(function(value){
                return value !== v
            });
        }

        this.setState({
            ...this.state,
            era: eras
        })
    }

    stableChanged(event){
        let stables = this.state.stable;
        let v = event.target.value;

        if( event.target.checked && ! stables.includes(v)){
            stables.push(v);
        }else{
            stables = stables.filter(function(value){
                return value !== v
            });
        }

        this.setState({
            ...this.state,
            stable: stables
        })
    }

    showBuilds () {
        let star = this.wwe.roster[this.state.superstar];
        let buildImgs = [];

        if( undefined !== star ){

            for ( let b = 0; b < 100; b++){
                try{
                    let imgSrc = require(`../imgs/superstars/${star.guide}/${star.guide}-${b}.png`);
                    buildImgs.push(imgSrc);
                }catch(error){
                }
            }

            return(
                <div>
                    <div className="text-center px-3 pt-3 mb-3 border border-bottom border-3">
                        <button onClick={this.closingBuild} className="float-end"><Icon icon="material-symbols:close" /></button>
                        <h1 className="fs-3">{ star.name }</h1>
                    </div>
                    <div className="mb-3">
                        <div id="superstarCarousel" className="carousel carousel-dark slide">
                            <div className="carousel-inner">
                                {  buildImgs.map((imgSrc, b) => {
                                    return(
                                        <div key={`${star.name} Build ${b}`} className={ (0 === b ? 'active ' : '') + "text-center carousel-item" }  >
                                            <img src={imgSrc} alt={ `${star.name} Build ${b}` } />
                                            <figcaption>{`Build ${b + 1}`}</figcaption>
                                        </div>
                                    )
                                }) }
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#superstarCarousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#superstarCarousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

    }

    showingBuild (superstarIndex) {
        this.setState({ 
            showingBuild: true,
            superstar: parseInt(superstarIndex) 
        });
    }
      
    closingBuild () {
        this.setState({ 
            showingBuild: false,
            superstar: null 
        });
    }
    
    render(){
        const renderSuperstarGrid = this.renderSuperstarGrid;
        const eraChanged = this.eraChanged;
        const stableChanged = this.stableChanged;

        let superstars = this.wwe.roster;

        let currentGender = this.state.gender;
        let currentClass = this.state.class;
        let buildOnlyEnabled = this.state.buildOnly;
        let eras = this.state.era;
        let stables = this.state.stable;

        let viewableStars = [];

        Object.values( superstars ).forEach( function(superstar, index){ 
            // class matches currentClass and
            // eras not selected or superstar is in era
            // stables not selected or superstar is in stable
            // gender is both or gender matches currentGender
            // either build only is enabled and the superstar has builds or build only is not enabled
            if( superstar.class === currentClass && 
                ( ! eras.length || eras.includes(superstar.era) ) && 
                ( ! stables.length || stables.includes(superstar.stable) ) && 
                ( superstar.gender === currentGender || "both" === currentGender ) && 
                ( (buildOnlyEnabled && superstar.hasBuilds) || ! buildOnlyEnabled ) 
            ){
                viewableStars[index] = superstar;
            } 
        })

        return(
            <>
            { ! Discord.isAppSiteMember() ? 
                <Discord className="d-none" clicked="true" /> : 
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-success d-inline-block p-1 mt-2" role="alert">
                                <Icon icon="carbon:checkmark-filled" className="fs-1 align-middle me-1"></Icon> 
                                This roster is complete and up-to-date as of { this.wwe.lastUpdate }. <strong>Total Superstar Count: <span id="roster-count">{ superstars.length }</span></strong>
                            </div>
                            <h3 className="text-decoration-underline mb-3">Superstar Information</h3>
                        </div>
                    </div>
                    <div className="d-flex align-items-end mb-3">
                        <div id="superstar-class" className="d-inline-block">
                            <strong className="d-block">Class</strong>
                            <select className="form-select text-center" onChange={ this.classChanged } defaultValue={currentClass.toLowerCase()}>
                                { 
                                    this.wwe.classes.map(function(c){
                                        return(
                                            <option 
                                                key={c + "-class"}
                                                id={ c + "-class" } 
                                                value={ c.toLowerCase() } 
                                                >
                                                { c }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <button className="border-secondary rounded ms-3" data-bs-target="#filterOptions" data-bs-toggle="collapse"><Icon icon="material-symbols:filter-alt"></Icon></button>
                        <strong className="ms-2">Showing <span id="display-count">{Object.keys(viewableStars).length}</span> superstars</strong>
                        {
                            Discord.isAppSiteAdmin() ?
                            <div className="ms-auto">
                                <button className="border-secondary rounded" onClick={ this.addSuperstar }><Icon icon="material-symbols:add"></Icon></button>
                                <button className="border-secondary rounded ms-3" onClick={ this.exportData }><Icon icon="material-symbols:cloud-download"></Icon></button>
                                <button className="border-secondary rounded ms-3" onClick={ this.editData }><Icon icon="material-symbols:settings-alert"></Icon></button>
                            </div>
                            : ''
                        }                    
                    </div>
                    <div id="filterOptions" className="row collapse border border-3 mb-3">
                        <div className="col-md-12">
                            <strong className="d-block">Eras</strong>
                            {
                                this.wwe.eras.map(function(e,i){
                                    let v = e.toLowerCase().replaceAll(' ', '_')
                                    return(
                                        <div className="form-check form-check-inline align-top" key={ `superstar-${v}-era`}>
                                            <input className="form-check-input" 
                                                required
                                                type="checkbox" 
                                                value={ v } 
                                                name="superstar-era" 
                                                id={ `superstar-${v}-era` } 
                                                onClick={ eraChanged }
                                                />
                                            <label className="form-check-label" htmlFor={ `superstar-${v}-era` }>{ e }</label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="col-md-12">
                            <strong className="d-block">Stables</strong>
                            {
                                this.wwe.stables.map(function(s,i){
                                    let v = s.toLowerCase().replaceAll(' ', '_')
                                    return(
                                        <div className="form-check form-check-inline align-top" key={ `superstar-${v}-era`}>
                                            <input className="form-check-input" 
                                                required
                                                type="checkbox" 
                                                value={ v } 
                                                name="superstar-stable" 
                                                id={ `superstar-${v}-era` } 
                                                onClick={ stableChanged }
                                                />
                                            <label className="form-check-label" htmlFor={ `superstar-${v}-era` }>{ s }</label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="col-md-12">
                            <strong className="d-block">Gender</strong>
                            <div className="form-check form-check-inline">
                                <input 
                                    required
                                    className="form-check-input" 
                                    type="radio" 
                                    value="both" 
                                    name="superstar-gender" 
                                    id="superstar-gender-both" 
                                    defaultChecked={ "both" === this.state.gender }
                                    onChange={ this.genderChanged } 
                                    />
                                <label className="form-check-label" htmlFor="superstar-gender-both">
                                    Both
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    required
                                    className="form-check-input" 
                                    type="radio" 
                                    value="m" 
                                    name="superstar-gender" 
                                    id="superstar-gender-male" 
                                    defaultChecked={ "m" === this.state.gender }
                                    onChange={ this.genderChanged } 
                                    />
                                <label className="form-check-label" htmlFor="superstar-gender-male">
                                    Male
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    required
                                    className="form-check-input" 
                                    type="radio" 
                                    value="f" 
                                    name="superstar-gender" 
                                    id="superstar-gender-female" 
                                    defaultChecked={ "f" === this.state.gender }
                                    onChange={ this.genderChanged } 
                                    />
                                <label className="form-check-label" htmlFor="superstar-gender-female">
                                    Female
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    name="build-only" 
                                    id="build-only"
                                    defaultChecked={ this.state.buildOnly }
                                    onChange={ this.isBuildOnly } 
                                    />
                                <label className="form-check-label" htmlFor="build-only">Show only superstars with builds</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            viewableStars.map(function(star, i){
                                return renderSuperstarGrid(star, i)
                            })
                        }
                    </div>
                    <ReactModal
                        id="add-edit-superstar"
                        className="w-50 h-auto bg-white position-absolute top-50 start-50 translate-middle container"
                        isOpen={ this.state.addingNewSuperStar || this.state.editingSuperstar }
                        contentLabel="Add a New Superstar"
                        >{  this.superstarForm()  }</ReactModal>
                    <ReactModal
                        id="edit-data"
                        className="w-50 h-auto bg-white position-absolute top-50 start-50 translate-middle container"
                        isOpen={ this.state.editingData }
                        contentLabel="Edit WWE Data"
                        >{  this.editDataForm()  }</ReactModal>
                    <ReactModal
                        id="remove-superstar"
                        className="w-50 h-auto bg-white position-absolute top-50 start-50 translate-middle container"
                        isOpen={ this.state.removingSuperstar }
                        contentLabel="Removing Superstar"
                        >{ this.renderRemoveSuperstarMessage() }</ReactModal>
                    <ReactModal
                        id="showBuilds"
                        className="container bg-light position-absolute top-50 start-50 translate-middle w-50"
                        contentLabel="Superstar Builds"
                        isOpen={ this.state.showingBuild }
                        onRequestClose={this.closingBuild}
                        shouldCloseOnOverlayClick={true}
                        >{ this.showBuilds() }</ReactModal>
                </div>
            }
            </> 
        );

    }

    renderSuperstarGrid(superstar, index){
        return(
            <div key={"superstar-" + index} className="text-center col-md-4 border-4 border pb-3">
                <div className="d-flex align-items-center mt-2">
                    <h5 className="mx-auto">{ superstar.name }</h5>
                    { 
                        Discord.isAppSiteAdmin() ? 
                            <>
                            <button 
                                className="border-secondary rounded ms-2" 
                                onClick={ () => { this.editSuperstar(index); } }
                                ><Icon icon="material-symbols:edit"></Icon>
                            </button>
                            <button 
                                className="text-danger border-danger rounded ms-2" 
                                onClick={ () => { this.removeSuperstar(index); } }
                                ><Icon data-superstar={index} icon="material-symbols:close"></Icon>
                            </button>
                            </>
                            : ''
                    }
                </div>
                <div>
                    <img className="superstar" alt={ superstar.name + " Image" } src={ silhouette } />
                </div>
                <div className="d-flex justify-content-center">
                    <a className="btn btn-info mt-3" target="_blank" rel="noreferrer" href={ `https://wwechampions.com/superstar-database/${superstar.guide}` } >Superstar Database</a>
                    {
                        superstar.hasBuilds ?
                        <button 
                            className="btn btn-secondary mt-3 ms-3" 
                            target="_blank" 
                            onClick={ () => { this.showingBuild(index); } }
                            >Builds</button>
                        : ''
                    }
                </div>
            </div>
        )
    }
    
}

