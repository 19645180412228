import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';

import App from './App';

import reportWebVitals from './reportWebVitals';

require('bootstrap');

const root = ReactDOM.createRoot(document.getElementsByTagName('main')[0]);
root.render(
  <>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
