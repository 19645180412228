import React from 'react';
import Navigation from './Navigation';

const Header = () => {
    return (
        <header className="bg-dark">
            <div id="skip-to-content"><a href="#main-content">Skip to Main Content</a></div>
            <div className="branding"></div>
            <Navigation />
        </header>
    );
}

export default Header;