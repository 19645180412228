import React from 'react';
import { Icon } from '@iconify/react';

import Discord from '../components/Discord';

import factions from '../data/faction.json';

export default class JoinUs extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            joining: false
        }

        this.renderRequestForm = this.renderRequestForm.bind(this);
        this.onJoinRequest = this.onJoinRequest.bind(this);
        this.onCancelRequest = this.onCancelRequest.bind(this);

    }

    onJoinRequest(e){

        if( 'function' === typeof(e.preventDefault) ){
            e.preventDefault();

            if(  e.target.checkValidity() ){
                const gamerTag = e.target.querySelector("#user-game-tag").value;
                const faction = factions[e.nativeEvent.submitter.dataset.faction];
                
    
                if( faction.discord_required && ! this.state.joining ){
    
                    this.setState({
                        ...this.state,
                        faction: faction,
                        tag: gamerTag,
                        joining: true,
                    })
    
    
                }else if( ! faction.discord_required || ( faction.discord_required && this.state.joining ) ){
                    
    
                    this.setState({
                        ...this.state,
                        joining: 'joined',
                        faction: faction,
                        tag: gamerTag
                    })
                }
            }
        }else{
            let fetchArgs = {
                headers : { 
                    Authorization: `${e.token_type} ${e.access_token}`,
                }
            }
    
            fetch('https://discord.com/api/users/@me', fetchArgs)
              .then( response => response.json() )
              .then( (data) => {
                    this.setState({
                        ...this.state,
                        joining: 'joined',
                        discord: `${data.username}#${data.discriminator}`
                    })
                }
            )
        }

        
    }

    onCancelRequest(e){
        this.setState({
            joining: false
        })
    }

    render(){
        let sendJoinEmail = 'string' === typeof(this.state.joining) && 
        'object' === typeof(this.state.faction) && 
        'string' === typeof(this.state.tag);
    
        let gamerTag = '';
        let faction = '';
    
        if( sendJoinEmail ){
            gamerTag = this.state.tag;
            faction = this.state.faction;
            let discord = 'string' === typeof(this.state.discord) ? `Discord: ${this.state.discord}\n\n` : '';

            let to = 'mailto:' + faction.recruiters.join(',');
            let subject = `subject=${this.state.joining} is interested in joining ${faction.name}!`;
            let body = `=== DO NOT EDIT THIS EMAIL ===\n\n`;

            body += `*** ${gamerTag} is interested in joining ${faction.name}! ***\n\n`;
            body += `Player Data: https://wwechampions.com/player/${gamerTag}\n\n`;
            body += `${discord}`;
            
            const mailto = document.createElement('a');
            mailto.href =   `${to}?${subject}&body=${encodeURIComponent(body)}`;

            mailto.click();
        }
        
        let faction_names = factions.map(faction => faction.name )
        return (
            <div className="container">
                <div className="row pt-2">
                    <div className="col-md-12 mb-3">
                        <h3>Join Us</h3>
                        <p>If you are interested in joining the {faction_names.join('/')} family, send us a request and a recruiter will review your profile and if considered you shall receive an in-game invite.</p>
                        <p>A list of the faction rules and minimum requirements can be viewed <a href="/rules">here</a>.</p>
                    </div>
                    {
                        true === this.state.joining ? 
                        <Discord clicked="true" reload="false" onAuthorized={this.onJoinRequest} onCancel={this.onCancelRequest} className="d-none"/> :
                        sendJoinEmail ?
                        <div className="col-md-12 mb-3">
                            <h3>Thank you {gamerTag} for your interest in {faction.name}!</h3>
                        </div>
                        : this.renderRequestForm()
                    }
                 </div>
            </div>
            );
    }

    renderRequestForm(){

        return(
            <div className="col-md-12 mb-3">
                <form onSubmit={this.onJoinRequest}>
                    <div className="row">
                        <div className="col-lg-6">
                            <strong>Game Tag</strong>
                            <input 
                                required
                                type="text" 
                                className="form-control" 
                                id="user-game-tag" 
                                name="user-game-tag" 
                                placeholder="Name" 
                                />
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-lg-12 text-center">
                            {
                                factions.map((faction, i) => {
                                    return(
                                        <button key={`faction-${i}`} data-faction={i} className="btn btn-primary ms-2">Request to Join { faction.name } <Icon icon="icon-park-outline:send-email"></Icon></button>
                                    );
                                })
                            }
                        </div>
                        <div className="col-lg-12">
                            {
                                factions.map((faction, i) => {
                                    if( faction.discord_required ){
                                        return(
                                            <strong key={`faction-${i}-discord-req`} className="d-block">* { faction.name } requires Discord</strong>
                                        );
                                    }
                                    return false;
                                })
                            }
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}