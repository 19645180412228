import React from 'react';

import factions from '../data/faction.json';

export default class Discord extends React.Component{
    oauthURL;
    popupRef;
    intervalRef;

    constructor(props){
        super(props);

        this.popupRef = React.createRef();
        this.intervalRef = React.createRef();

        this.getOAuthURL = this.getOAuthURL.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.authCheck = this.authCheck.bind(this);
        this.onAuthorized = this.onAuthorized.bind(this);
        this.onCancel = this.onCancel.bind(this);
        
        this.setPrivilege = this.setPrivilege.bind(this);

    }

    getOAuthURL(){

        const generateState = () => {
			const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			let array = new Uint8Array(10);
			window.crypto.getRandomValues(array);
			array = array.map((x) => validChars.codePointAt(x % validChars.length));
			const randomState = String.fromCharCode.apply(null, array);
			return randomState;
		};

        var authorizeUrl = 'https://discord.com/api/oauth2/authorize?response_type=token';
		var clientId = `&client_id=${process.env.REACT_APP_ACIDSITE_CLIENT_ID}`;
		var redirectUri = `&redirect_uri=${window.location.origin + window.location.pathname}`;
		var scopes = `&scope=${['identify', 'guilds'].join('%20')}`;
		var state = `&state=${generateState()}`;

        return `${authorizeUrl + clientId + scopes + state + redirectUri}`;
    }

    openPopup(){
        const POPUP_HEIGHT = 700;
      	const POPUP_WIDTH = 600;

        // To fix issues with window.screen in multi-monitor setups, the easier option is to
		// center the pop-up over the parent window.
		const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
		const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
		
        this.popupRef.current =  window.open(
			this.getOAuthURL(),
			'OAuthPopup Popup',
			`height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
		);

		this.intervalRef.current = setInterval(this.authCheck, 250)

    }

    authCheck(){
        const queryToObject = (query) => {
			const parameters = new URLSearchParams(query);
			return Object.fromEntries(parameters.entries());
		};
        
        // Closed without any authorization
		if( ! this.popupRef.current || ! this.popupRef.current.window || this.popupRef.current.window.closed ){
            clearInterval( this.intervalRef.current );
            this.popupRef.current?.close();

            this.onCancel('Was not authorized.');

            if( 'function' === typeof(this.props.onCancel) ){
                this.props.onCancel('Was not authorized.');
            }
		// Popup is still active
		}else if( undefined !== this.popupRef.current.window.location.search ){

			if( undefined !== this.popupRef.current.window.location.hash){
				var hashParams = queryToObject(this.popupRef.current.window.location.hash.slice(1));

				// Popup was authorized and token was provided from Discord
				if( hashParams.access_token && hashParams.token_type){
                    clearInterval( this.intervalRef.current );
					this.popupRef.current.close();
                    
                    this.onAuthorized(hashParams);

                    if( 'function' === typeof(this.props.onAuthorized) ){
                        this.props.onAuthorized(hashParams);
                    }
				}

				// Popup was cancelled
				if( hashParams.error ){
                    clearInterval( this.intervalRef.current );
					this.popupRef.current?.close();

                    this.onCancel(hashParams.error);
            
                    if( 'function' === typeof(this.props.onCancel) ){
                        this.props.onCancel(hashParams.error);
                    }
				}
			}

		}
    }

    onAuthorized(params){
        let faction_servers = factions.map( f => f.discord_server )

        let fetchArgs = {
			headers : { 
                Authorization: `${params.token_type} ${params.access_token}`,
			}
		}

		fetch('https://discord.com/api/users/@me/guilds', fetchArgs)
		  .then( response => response.json() )
		  .then( (data) => {
				var allowed = false;

				data.forEach((server) => {
					if(  faction_servers.includes(server.name) ){
						allowed = true;
					}
				})

				this.setPrivilege(allowed, params.token_type, params.access_token);
			}
		)
    }

    onCancel(msg){
        console.log(msg);
    }

    static isAppSiteMember(){
        return 'true' === sessionStorage.getItem('app-site-member')
    }

    static isAppSiteAdmin(){
        return 'true' === sessionStorage.getItem('app-site-admin')
    }

    static getAppSiteMemberToken(){
        return sessionStorage.getItem('app-site-token')
    }

    async setPrivilege(privilege, type = '', token = ''){
        if( "false" !== this.props.reload ){
            sessionStorage.setItem('app-site-member', privilege );
            sessionStorage.setItem('app-site-type', type );
            sessionStorage.setItem('app-site-token', token );
    
    
            if( privilege ){
                let fetchArgs = {
                    headers : { 
                                Authorization: `${type} ${token}`,
                    }
                }
                
                const user = await fetch('https://discord.com/api/users/@me',fetchArgs).then( response => response.json() )
                const admins = process.env.REACT_APP_ACIDSITE_ADMINS.split(',');
        
                if( admins.includes(`${user.username}`) ){
                    sessionStorage.setItem('app-site-admin', true );
                }
    
            }

           window.location.reload();
        }
    }

    render(){
        let btnLabel = undefined !== this.props.children ? this.props.children : 'Authorize'

        if( "true" === this.props.clicked ){
            this.openPopup();
        }

        return (
            <button 
                className={ undefined !== this.props.className ? this.props.className : '' } 
                onClick={ this.openPopup }
            >{ btnLabel }</button>
        );

    }

}